.base:hover {
    opacity: 0.5;
    cursor: pointer;
}

.base {
    position: absolute;
}

.arrow {
    background-color: #999;
    position: relative;
    left: 0;
    width: 80%;
    height: 5px;
    border-radius: 5px;
    transition: all 0.1s ease-out;
}

.upper {
    transform: rotate(-30deg);
    top: 25%;
}

.lower {
    transform: rotate(30deg);
    top: 75%;
}

.slimStile {
    transform: rotate(0deg);
    top: 50%;
}