.base {
    position: relative;
    overflow: hidden;
    border: 1px solid #AAA;
}

.base>.innerBase {
    position: absolute;
    top: 0;
    left: 0;
}

.palet {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.5s ease-out;
}