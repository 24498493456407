.cover {
    display: inline-block;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pen {
    width: 60px;
    height: 60px;
}

.pen:nth-child(n+2) {
    margin-left: -12px;
}

.pen:hover {
    cursor: pointer;
    opacity: 0.5;
}

.penSizeArea {
    display: inline-block;
    text-align: center;
}