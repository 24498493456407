.cover {
    opacity: 0.6;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: none;
}

.dialog {
    opacity: 1.0;
    z-index: 99999;
    width: 60vw;
    height: 60vh;
    position: fixed;
    top: 20vh;
    left: 20vw;
    background-color: white;
    border: 1px solid #999;
    border-radius: 10px;
    display: none;
}

.dialog>.title {
    height: 40px;
    padding: 10px;
    border-bottom: 1px solid #DDD;
}

.dialog>.body {
    padding: 10px;
    height: calc(60vh - 40px - 40px);
    overflow-y: scroll;
}

.dialog>.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    border-top: 1px solid #DDD;
    padding: 10px;
    text-align: right;
}

.dialog>.footer>span {
    margin-left: 10px;
}

.dialog>.footer>span:hover {
    opacity: 0.5;
    cursor: pointer;
}