.area {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 9999;
    transition: all 1.0s ease-out;
    -webkit-user-select: none;
    user-select: none;
}
.area div {
    display: inline-block;
}
.area button {
    height: 30px;
    margin: 5px;
    vertical-align: middle;
    background-color: white;
    border: solid 1px #DDD;
    border-radius: 5px;
}