.indexBody {
    width: 100vw;
    scroll-snap-type: y mandatory;
    overflow: auto;
}

.boxArea {
    scroll-snap-align: start;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.box {
    position: fixed;
    display: inline-block;
    /*display: none;*/
    transition: all 0.5s ease-out;
}

.box0 {
    background-color: #bd0;
    width: 66.6%;
    height: 100%;
    left: 0;
    top: 0
}

.box1 {
    background-color: #db0;
    width: 33.4%;
    height: 66.6%;
    right: 0;
    top: 0;
}

.box2 {
    display: none;
    background-color: rgb(202, 216, 214);
    width: 33.4%;
    height: 33.3%;
    right: 0;
    top: 33.3%;
}

.box3 {
    background-color: #0bd;
    width: 33.4%;
    height: 33.4%;
    right: 0;
    top: 66.6%;
}

.read, .infoArea, .goToList {
    scroll-snap-align: start;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
}

.read>div {
    font-size: 10vw;
}

.infoArea>div {
    display: inline-block;
    text-align: left;
    font-size: 2vw;
}

.infoArea>div>li {
    list-style-image: url('./pen.svg')
}

.goToList {
    flex-flow: column;
}

.goToList>div {
    display: block;
}

.goToList>.link>a {
    font-size: 4vw;
    text-decoration: none;
}

.goToList>.linkInfo {
    font-size: 2vw;
}

.flexGotoLink {
    position: fixed;
    top: 0;
    left: 10px;
}