.share {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9999;
    transition: all 1.0s ease-out;
    user-select: none;
}

.backArrow {
    width: 30px;
    height: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9999;
    transition: all 0.5s ease-out;
}

.share:hover {
    cursor: pointer;
    opacity: 0.5;
}