.base {
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #DDD;
    padding: 5px;
}

.pluse {
    width: 100%;
    height: 100%;
}

.pluse:hover {
    opacity: 0.5;
    cursor: pointer;
}

.svg {
    transform: rotate(45deg);
}