.base {
    position: relative;
    overflow: hidden;
}

.base>.innerBase {
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
}