.imageBox {
    border: 1px solid #000;
    margin-right: 10px;
    display: inline-block;
}

.plusIcon {
    width: 30px;
    height: 30px;
    margin: 5px;
    vertical-align: middle;
}

.mondaiButton {
    height: 30px;
    margin: 5px;
    vertical-align: middle;
    background-color: white;
    border: solid 1px #DDD;
    border-radius: 5px;
}

.imageBox:hover, .plusIcon:hover, .mondaiButton:hover {
    opacity: 0.5;
    cursor: pointer;
}

.listBase {
    margin: 5px;
}